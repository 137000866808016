import '../App.css';
import axios from 'axios'
import logo from '../ip.png';
import CheckIcon from '@mui/icons-material/Check';
import React, {useEffect, useState} from 'react';
import {
    Alert,
    Avatar,
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Button,
    Checkbox,
    CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField
} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import SellTwoToneIcon from '@mui/icons-material/SellTwoTone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LyricsTwoToneIcon from '@mui/icons-material/LyricsTwoTone';
import Price from "./price";
import {useDispatch, useStore} from "react-redux";
import {setOrder, setTable, setToken} from "../store/user";
import Favorites from "./favorites";

function Dashboard() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const store = useStore();
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [locale, setLocale] = useState('ru');
    const [stateToken, setStateToken] = useState(null);
    const [maxPage, setMaxPage] = useState(10);
    const [limit] = useState(200);
    const [total, setTotal] = useState(0);
    const [appState, setAppState] = useState([]);
    const [checked, setChecked] = useState([]);
    const [alert, setAlert] = useState([]);
    const [viewPaymentButton, setViewPaymentButton] = useState(false);
    const [price, setPrice] = useState(0);
    const [screen, setScreen] = useState('price');
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        console.log(screen);
        if (screen === 'songs') {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener("scroll", handleScroll);
        }
    }, [screen])

    const handleScroll = (e) => {
        console.log(screen, window.innerHeight + window.scrollY,document.body.clientHeight - 5, screen === 'songs' && window.innerHeight + window.scrollY > document.body.clientHeight - 5)
        if (screen === 'songs' && window.innerHeight + window.scrollY > document.body.clientHeight - 150) {
            console.log(page, total, limit, page + 1)
            onPaginateChange(page + 1);
            getSongs();
        }
    }

    useEffect(() => {
        let table = searchParams.get('table');
        table = table && table !== 'null' ? table : store.getState().user.table;
        if (!table || table === 'null') {
            // setAlert({text: "Отсканируйте qr код заново", type: 'error'})
            setOpenDialog(true);
        }

        let token = searchParams.get('token');
        token = token && token !== 'null' ? token : store.getState().user.token;
        if (!token || token === 'null') {
            console.log('asdasd')
            setNavigate({route: 'login', table: searchParams.get('table')})
            return
        }

        setStateToken(token);

        const order = searchParams.get('orderId');
        if (order && order !== store.getState().user.order) {
            dispatch(setOrder(order))
            checkOrder(order);
        }
        getSongs();
    }, [locale]);


    useEffect(() => {
        if (checked.length > 0) {
            console.log('asdasd', price)
            setViewPaymentButton(true)
        } else {
            setViewPaymentButton(false)
        }
    }, [checked.length]);

    useEffect(() => {
        setTimeout(() => setAlert([]), 6000)
    }, [alert.length]);

    const setNavigate = (values) => {
        console.log('nav',values)
        let path = '';
        if (values.route) {
            path = '/'+values.route+'/?';
        } else {
            path = '/?';
        }
        if (values.table) {
            path = path+'table='+values.table+'&'
        }
        if (values.token) {
            path = path+'token='+values.token+'&'
        }
        navigate(path)
    }

    const checkOrder = async (order) => {
        let token =  store.getState().user.token || searchParams.get('token');
        await axios.get(process.env.REACT_APP_BACK_API+'/check/'+order, {
            headers: {
                Authorization: 'Bearer '+token
            }
        }).then((resp) => {
            const data = resp.data;
            if (data.payed) {
                setAlert({text: "Успех, выбранные песни будут добавлены в плейлист", type: 'success'});
                setNavigate({table: searchParams.get('table'), token: searchParams.get('token')})
            }
        }).catch((e) => {
            if (e.response && (e.response.status === 401 || e.response.status === 403)) {
                setNavigate({route: 'login', table: searchParams.get('table')})
            }
            console.error(e)
        });
    }

    const getSongs = async () => {
        let token =  store.getState().user.token || searchParams.get('token')
        await axios.get(process.env.REACT_APP_BACK_API+'/songs?page='+page+'&limit='+limit+'&locale='+locale, {
            headers: {
                Authorization: 'Bearer '+token
            }
        }).then((resp) => {
            window.scrollTo(0, 0);
            const allSongs = resp.data.data;
            setMaxPage(resp.data.meta.last_page)
            console.log(resp.data.meta.total)
            setTotal(resp.data.meta.total)
            setAppState(allSongs);
        }).catch((e) => {
            if (e.response.status === 401 || e.response.status === 403) {
                dispatch(setToken(null))
                setNavigate({route: 'login', table: searchParams.get('table')})
            }
        });
    }

    const search = async (searchTerm) => {
        await axios.get(process.env.REACT_APP_BACK_API+'/songs?limit=100&search='+searchTerm,{
            headers: {
                Authorization:'Bearer '+stateToken
            }
        }).then((resp) => {
            setAppState(resp.data.data)
        }).catch((e) => {
            if (e.response.status === 401 || e.response.status === 403) {
                setNavigate({route: 'login', table: searchParams.get('table')})
            }
        });
    };

    const sendChecked = () => {
        let token =  store.getState().user.token || searchParams.get('token');
        let table =  store.getState().user.table || searchParams.get('table');
        axios.post(process.env.REACT_APP_BACK_API+'/order', {
            songs: checked.map((i) => i.id),
            table: table,
            returnLink: '?table='+table+'&token='+token
        }, {
            headers: {
                Authorization:'Bearer '+token
            }
        }).then((resp) => {
            console.log(resp)
            setChecked([])
            if (resp.data.link) {
                window.location.replace(resp.data.link)
            } else {
                setAlert({text: "Успех, выбранные песни будут добавлены в плейлист", type: 'success'})
            }
        }).catch((e) => {
            if (e.response.status === 401 || e.response.status === 403) {
                setNavigate({route: 'login', table: searchParams.get('table')})
            }
            setAlert({text: 'Повторите попытку, если проблема не ушла обратитесь к администратору', type: 'error'})
        });
    }


    const onInputChange = (value) => {
        let a = value.trim()
        if (a) {
            search(a);
        } else {
            // getSongs()
        }
    }

    const onPaginateChange = (number) => {
        setPage(number);
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.findIndex((item) => item.id  === value.id);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        if (newChecked.length <= 3) {
            setChecked(newChecked);
            if (newChecked.length > 0) {
                setPrice(store.getState().prices.microPrice[newChecked.length].price / 100);
            } else {
                setPrice(0);
            }
        } else {
            setAlert({text: 'Заказать за 1 раз можно не больше 3х композиций!', type: 'error'})
            // setAlert({text: 'Пожалуйста оплатите выбранные композиции и выбирайте дальше!', type: 'error'})
        }
    };

    return (
        <Box sx={viewPaymentButton ? {pt: 20} : {pt: 14}} style={{maxWidth: '500px', margin: '0 auto', display: 'block'}}>
            <Paper sx={{position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1}} elevation={3}>
                {alert.length !== 0 && <div>
                    <Alert icon={<CheckIcon fontSize="inherit"/>} severity={alert.type}>
                        {alert.text}
                    </Alert>
                </div>}
                <div style={{width: '90%', margin: "5px auto", display: 'flex', justifyContent: 'space-between'}}>
                    <TextField label="поиск" variant="outlined"
                               onChange={(event) => onInputChange(event.target.value)}
                               style={{width: '60%', maxWidth:'80%'}}/>
                    <Select
                        style={{maxWidth: '40%'}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={locale}
                        label="Локализация"
                        onChange={(e) => setLocale(e.target.value)}
                    >
                        <MenuItem value='ru'>Русские</MenuItem>
                        <MenuItem value='en'>Зарубежные</MenuItem>
                        <MenuItem value='all'>Смешанное</MenuItem>
                    </Select>
                </div>
                {viewPaymentButton && price > 0 && <div style={{width: '80%', margin: "20px auto"}}>
                    <Button style={{width: '100%', margin: "auto"}} variant="outlined" color="success"
                            onClick={() => sendChecked()}
                    >
                        к оплате {price} рублей
                        {/*заказать выбранные*/}
                    </Button>
                    {/*<Button style={{width: '100%', margin: "5px auto"}} variant="outlined" color="error"*/}
                    {/*        onClick={() => {*/}
                    {/*          setChecked([]);*/}
                    {/*          setPrice(0);*/}
                    {/*        }}*/}
                    {/*>*/}
                    {/*  удалить выбранные*/}
                    {/*</Button>*/}
                </div>}
            </Paper>
            <CssBaseline/>
            {screen === 'price' && <Price />}
            {screen === 'favorites' && <Favorites />}
            {screen === 'songs' && <List dense>
                {appState.map((value, i) => {
                    const labelId = `checkbox-list-secondary-label-${value}`;
                    return (
                        <ListItem
                            key={i}
                            secondaryAction={
                                <Checkbox
                                    edge="end"
                                    onChange={handleToggle(value)}
                                    checked={!!checked.find((item) => item.id === value.id)}
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            }
                            disablePadding
                        >
                            <ListItemButton>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={value.singer}
                                        src={logo}
                                    />
                                </ListItemAvatar>
                                <ListItemText id={labelId} primary={value.singer} secondary={value.song}/>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>}

            {/*{screen === 'songs' && <Stack spacing={2} style={{width: '80%', margin: "auto"}}>*/}
            {/*    <Pagination variant="outlined" color="secondary" size="small"*/}
            {/*                page={page}*/}
            {/*                count={maxPage}*/}
            {/*                onChange={(event, page) => onPaginateChange(page)}*/}
            {/*    />*/}
            {/*</Stack>}*/}
            <Dialog
                open={openDialog}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const table = formJson.table;
                        console.log(table);
                        dispatch(setTable(table));
                        setOpenDialog(false)
                        setNavigate({table, token: searchParams.get('token') || store.getState().user.token})
                        // handleClose();
                    },
                }}
            >
                <DialogTitle>Стол не найден!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                       Чтобы заказывать песни, нужно указать номер стола, пожалуйста введите номер или заново отсканируйте QR код на столе!
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="table"
                        label="Введите номер стола"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    {/*<Button onClick={handleClose}>Cancel</Button>*/}
                    <Button type="submit">Указать</Button>
                </DialogActions>
            </Dialog>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={screen}
                    onChange={(event, newValue) => {
                        setScreen(newValue);
                    }}
                >
                    <BottomNavigationAction value='price' label="Прайс-лист" icon={<SellTwoToneIcon />} />
                    <BottomNavigationAction value='songs' label="Композиции" icon={<LyricsTwoToneIcon />} />
                    <BottomNavigationAction value='favorites' label="Мой выбор" icon={<FavoriteIcon />} />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}

export default Dashboard;